export const Alphabets = {
  "Nucleotide": {
    "canonical": "ACGT",
    "wildcard": "N",
    "ambiguities": {
      "M": "AC",
      "R": "AG",
      "W": "AT",
      "S": "CG",
      "Y": "CT",
      "K": "GT",
      "B": "CGT",
      "D": "AGT",
      "H": "ACT",
      "V": "ACG",
      "N": "ACGT",
      "?": "ACGT"
    },
    "names": {
      "A": "Adenine",
      "C": "Cytosine",
      "G": "Guanine",
      "T": "Thymine",
      "I": "Inosine",
      "U": "Uracil"
    },
    "reverseAmbiguities": {
      "AC": "M",
      "AG": "R",
      "AT": "W",
      "CG": "S",
      "CT": "Y",
      "GT": "K",
      "CGT": "B",
      "AGT": "D",
      "ACT": "H",
      "ACG": "V"
    }
  },
  "AminoAcid": {
    "canonical": "ACDEFGHIKLMNPQRSTVWY",
    "wildcard": "X",
    "ambiguities": {
      "B": "DN",
      "Z": "EQ",
      "J": "IL",
      "X": "ACDEFGHIKLMNPQRSTVWY",
      "?": "ACDEFGHIKLMNPQRSTVWY"
    },
    "names": {
      "A": "Alanine",
      "C": "Cysteine",
      "D": "Aspartic Acid",
      "E": "Glutamic Acid",
      "F": "Phenylalanine",
      "G": "Glycine",
      "H": "Histidine",
      "I": "Isoleucine",
      "K": "Lysine",
      "L": "Leucine",
      "M": "Methionine",
      "N": "Asparagine",
      "P": "Proline",
      "Q": "Glutamine",
      "R": "Arginine",
      "S": "Serine",
      "T": "Threonine",
      "V": "Valine",
      "W": "Tryptophan",
      "X": "Any amino acid",
      "Y": "Tyrosine"
    },
    "abbreviations": {
      "A": "Ala",
      "B": "Asx",
      "C": "Cys",
      "D": "Asp",
      "E": "Glu",
      "F": "Phe",
      "G": "Gly",
      "H": "His",
      "I": "Ile",
      "K": "Lys",
      "L": "Leu",
      "M": "Met",
      "N": "Asn",
      "P": "Pro",
      "Q": "Gln",
      "R": "Arg",
      "S": "Ser",
      "T": "Thr",
      "V": "Val",
      "W": "Trp",
      "X": "Xaa",
      "Y": "Tyr",
      "Z": "Glx"
    },
    "reverseAmbiguities": {
      "DN": "B",
      "EQ": "Z",
      "IL": "J"
    }
  }
};
export const AnnotationTypes = {
  "Known": {
    "CDS": {
      "color": "rgb(255,255,0)"
    },
    "gene": {
      "color": "rgb(0,178,0)"
    },
    "terminator": {
      "color": "rgb(255,83,0)"
    },
    "promoter": {
      "color": "rgb(186,255,0)"
    },
    "regulatory": {
      "color": "rgb(14,160,137)"
    },
    "extracted region": {
      "color": "rgb(255,200,0)"
    },
    "Excluded Region": {
      "color": "rgb(160,0,0)"
    },
    "primer_bind": {
      "color": "rgb(0,124,0)"
    },
    "primer_bind_reverse": {
      "color": "rgb(0,255,0)"
    },
    "DNA probe bind": {
      "color": "rgb(255,0,0)"
    },
    "trimmed": {
      "color": "rgb(255,175,175)"
    },
    "Masked": {
      "color": "rgb(238,238,238)"
    },
    "source": {
      "color": "rgb(0,0,255)"
    },
    "Junction": {
      "color": "rgb(0,255,127)"
    },
    "exon": {
      "color": "rgb(100,100,100)"
    },
    "ORF": {
      "color": "rgb(255,153,0)"
    },
    "restriction site": {
      "color": "rgb(11,8,132)"
    },
    "helix": {
      "color": "rgb(255,70,255)"
    },
    "strand": {
      "color": "rgb(230,195,0)"
    },
    "turn": {
      "color": "rgb(35,100,255)"
    },
    "coil": {
      "color": "rgb(204,204,204)"
    },
    "polylinker": {
      "color": "rgb(113,0,168)"
    },
    "transmembrane region": {
      "color": "rgb(180,0,0)"
    },
    "coiled-coil region": {
      "color": "rgb(180,140,255)"
    },
    "Editing History Deletion": {
      "color": "rgb(255,0,0)"
    },
    "Editing History Insertion": {
      "color": "rgb(0,255,0)"
    },
    "Editing History Replacement": {
      "color": "rgb(255,200,0)"
    },
    "gap": {
      "color": "rgb(238,238,238)"
    },
    "rep_origin": {
      "color": "rgb(80,179,255)"
    },
    "origin of replication": {
      "color": "rgb(80,179,255)"
    },
    "sig_peptide": {
      "color": "rgb(255,115,255)"
    },
    "signal peptide": {
      "color": "rgb(255,115,255)"
    },
    "misc_signal": {
      "color": "rgb(255,115,150)"
    },
    "motif": {
      "color": "rgb(150,0,140)"
    },
    "motif of biological interest": {
      "color": "rgb(150,0,140)"
    },
    "polyA_signal": {
      "color": "rgb(0,204,204)"
    },
    "polyA_site": {
      "color": "rgb(0,255,255)"
    },
    "intron": {
      "color": "rgb(245,245,245)"
    },
    "5'UTR": {
      "color": "rgb(245,245,245)"
    },
    "3'UTR": {
      "color": "rgb(245,245,245)"
    },
    "region": {
      "color": "rgb(170,170,170)"
    },
    "region of interest": {
      "color": "rgb(170,170,170)"
    },
    "misc_feature": {
      "color": "rgb(170,170,170)"
    },
    "LTR": {
      "color": "rgb(231,150,10)"
    },
    "repeat_region": {
      "color": "rgb(231,150,10)"
    },
    "repeat": {
      "color": "rgb(231,150,10)"
    },
    "repeat_unit": {
      "color": "rgb(175,211,255)"
    },
    "prim_transcript": {
      "color": "rgb(190,50,50)"
    },
    "mRNA": {
      "color": "rgb(190,50,50)"
    },
    "tmRNA": {
      "color": "rgb(190,50,50)"
    },
    "tRNA": {
      "color": "rgb(255,0,160)"
    },
    "rRNA": {
      "color": "rgb(255,0,0)"
    },
    "ncRNA": {
      "color": "rgb(190,100,100)"
    },
    "precursor_RNA": {
      "color": "rgb(255,70,0)"
    },
    "misc_RNA": {
      "color": "rgb(255,0,100)"
    },
    "chain": {
      "color": "rgb(0,255,0)"
    },
    "Protein": {
      "color": "rgb(0,255,0)"
    },
    "peptide": {
      "color": "rgb(0,255,0)"
    },
    "mat_peptide": {
      "color": "rgb(0,90,40)"
    },
    "proprotein": {
      "color": "rgb(185,230,200)"
    },
    "propeptide": {
      "color": "rgb(185,230,200)"
    },
    "disulfide bond": {
      "color": "rgb(255,255,0)"
    },
    "Bond": {
      "color": "rgb(255,255,0)"
    },
    "protein_bind": {
      "color": "rgb(180,100,0)"
    },
    "dna binding region": {
      "color": "rgb(180,100,0)"
    },
    "calcium-binding region": {
      "color": "rgb(120,50,0)"
    },
    "metal ion binding site": {
      "color": "rgb(120,50,0)"
    },
    "covalent binding of a lipid moiety": {
      "color": "rgb(120,50,0)"
    },
    "binding site": {
      "color": "rgb(180,100,0)"
    },
    "sequence variant": {
      "color": "rgb(255,200,0)"
    },
    "variation": {
      "color": "rgb(255,180,0)"
    },
    "Polymorphism": {
      "color": "rgb(255,180,0)"
    },
    "Optimized Codon": {
      "color": "rgb(255,50,50)"
    },
    "misc_difference": {
      "color": "rgb(255,180,0)"
    },
    "unsure": {
      "color": "rgb(255,180,0)"
    },
    "source conflict": {
      "color": "rgb(255,100,0)"
    },
    "conflict": {
      "color": "rgb(255,100,0)"
    },
    "modified_base": {
      "color": "rgb(255,100,0)"
    },
    "posttranslational modification of a residue": {
      "color": "rgb(255,0,0)"
    },
    "site": {
      "color": "rgb(0,0,140)"
    },
    "glycosylation site": {
      "color": "rgb(130,0,255)"
    },
    "site of interest": {
      "color": "rgb(0,0,140)"
    },
    "active site": {
      "color": "rgb(0,0,255)"
    },
    "domain": {
      "color": "rgb(255,100,100)"
    },
    "topological domain": {
      "color": "rgb(255,204,204)"
    },
    "vector_weak": {
      "color": "rgb(0,255,49)"
    },
    "vector_moderate": {
      "color": "rgb(255,0,255)"
    },
    "vector_strong": {
      "color": "rgb(255,0,0)"
    },
    "vector_suspect": {
      "color": "rgb(255,255,0)"
    },
    "Concatenated sequence": {
      "color": "rgb(200,200,255)"
    },
    "overhang": {
      "color": "rgb(149,226,255)"
    },
    "attB1": {
      "color": "rgb(0,0,128)"
    },
    "attB2": {
      "color": "rgb(0,0,192)"
    },
    "attB3": {
      "color": "rgb(0,0,144)"
    },
    "attB3r": {
      "color": "rgb(0,0,144)"
    },
    "attB4": {
      "color": "rgb(0,0,160)"
    },
    "attB4r": {
      "color": "rgb(0,0,160)"
    },
    "attB5": {
      "color": "rgb(0,0,176)"
    },
    "attB5r": {
      "color": "rgb(0,0,176)"
    },
    "attP1": {
      "color": "rgb(128,128,128)"
    },
    "attP2": {
      "color": "rgb(128,128,224)"
    },
    "attP3": {
      "color": "rgb(128,128,144)"
    },
    "attP3r": {
      "color": "rgb(128,128,144)"
    },
    "attP4": {
      "color": "rgb(128,128,160)"
    },
    "attP4r": {
      "color": "rgb(128,128,160)"
    },
    "attP5": {
      "color": "rgb(128,128,176)"
    },
    "attP5r": {
      "color": "rgb(128,128,176)"
    },
    "attL1": {
      "color": "rgb(0,128,0)"
    },
    "attL2": {
      "color": "rgb(0,192,0)"
    },
    "attR1": {
      "color": "rgb(128,192,128)"
    },
    "attR2": {
      "color": "rgb(128,255,128)"
    },
    "Statistics": {
      "color": "rgb(60,179,113)"
    },
    "Expression Level": {
      "color": "rgb(0,0,255)"
    },
    "Expression Difference": {
      "color": "rgb(180,0,0)"
    },
    "CRISPR": {
      "color": "rgb(0,184,250)"
    },
    "IMGT": {
      "color": "rgb(149, 226, 255)"
    }
  },
  "Related": [["FR", "CDR"], ["extron", "intron"], ["primer_bind", "primer_bind_reverse"], ["helix", "strand", "coil", "turn"], ["Editing History Insertion", "Editing History Replacement", "Editing History Deletion"]],
  "UnknownColors": ["rgb(  0,  0,255)", "rgb(255,  0,  0)", "rgb(255,255,  0)", "rgb(  0,255,  0)", "rgb(255,200,  0)", "rgb(255,175,175)", "rgb(  0,255,255)", "rgb(255,  0,255)", "rgb(150,  0,  0)", "rgb(163,191,  0)", "rgb(255,255,185)", "rgb(240,200,  0)", "rgb(  0,128,  0)", "rgb(  0,151,143)", "rgb(149,226,255)", "rgb(238,238,238)", "rgb(145,145,145)", "rgb(  0,  0,157)", "rgb(173,106,255)", "rgb(122,  0,122)", "rgb(170,  0,  0)", "rgb(220,170,140)", "rgb( 85, 85, 85)", "rgb( 46,150,255)"]
};
export const GeneticCodes = {
  "amber": {
    "name": "Amber Readthrough",
    "geneiousPrimeID": "Amber Readthrough",
    "translations": "FFLLSSSSYY*QCC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "---M------*---*----M---------------M----------------------------"
  },
  "transl_table=1": {
    "name": "Standard",
    "geneiousPrimeID": "universal",
    "translations": "FFLLSSSSYY**CC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**--*----M---------------M----------------------------"
  },
  "transl_table=2": {
    "name": "Vertebrate Mitochondrial",
    "geneiousPrimeID": "vertebrateMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIMMTTTTNNKKSS**VVVVAAAADDEEGGGG",
    "startAndStop": "----------**--------------------MMMM----------**---M------------"
  },
  "transl_table=3": {
    "name": "Yeast Mitochondrial",
    "geneiousPrimeID": "yeast",
    "translations": "FFLLSSSSYY**CCWWTTTTPPPPHHQQRRRRIIMMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**----------------------MM----------------------------"
  },
  "transl_table=4": {
    "name": "Mold Protozoan Mitochondrial",
    "geneiousPrimeID": "moldProtozoanMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--MM------**-------M------------MMMM---------------M------------"
  },
  "transl_table=5": {
    "name": "Invertebrate Mitochondrial",
    "geneiousPrimeID": "invertebrateMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIMMTTTTNNKKSSSSVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**--------------------MMMM---------------M------------"
  },
  "transl_table=6": {
    "name": "Ciliate",
    "geneiousPrimeID": "ciliate",
    "translations": "FFLLSSSSYYQQCC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--------------*--------------------M----------------------------"
  },
  "transl_table=9": {
    "name": "Echinoderm Mitochondrial",
    "geneiousPrimeID": "echinodermMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIIMTTTTNNNKSSSSVVVVAAAADDEEGGGG",
    "startAndStop": "----------**-----------------------M---------------M------------"
  },
  "transl_table=10": {
    "name": "Euplotid Nuclear",
    "geneiousPrimeID": "euplotidNuclear",
    "translations": "FFLLSSSSYY**CCCWLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**-----------------------M----------------------------"
  },
  "transl_table=11": {
    "name": "Bacterial",
    "geneiousPrimeID": "bacterial",
    "translations": "FFLLSSSSYY**CC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**--*----M------------MMMM---------------M------------"
  },
  "transl_table=12": {
    "name": "Alternative Yeast",
    "geneiousPrimeID": "alternativeYeast",
    "translations": "FFLLSSSSYY**CC*WLLLSPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**--*----M---------------M----------------------------"
  },
  "transl_table=13": {
    "name": "Ascidian Mitochondrial",
    "geneiousPrimeID": "ascidianMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIMMTTTTNNKKSSGGVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**----------------------MM---------------M------------"
  },
  "transl_table=14": {
    "name": "Flatworm Mitochondrial",
    "geneiousPrimeID": "flatwormMitochondrial",
    "translations": "FFLLSSSSYYY*CCWWLLLLPPPPHHQQRRRRIIIMTTTTNNNKSSSSVVVVAAAADDEEGGGG",
    "startAndStop": "-----------*-----------------------M----------------------------"
  },
  "transl_table=16": {
    "name": "Chlorophycean Mitochondrial",
    "geneiousPrimeID": "chlorophyceanMitochondrial",
    "translations": "FFLLSSSSYY*LCC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------*---*--------------------M----------------------------"
  },
  "transl_table=21": {
    "name": "Trematode Mitochondrial",
    "geneiousPrimeID": "trematodeMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIMMTTTTNNNKSSSSVVVVAAAADDEEGGGG",
    "startAndStop": "----------**-----------------------M---------------M------------"
  },
  "transl_table=22": {
    "name": "Scenedesmus Obliquus Mitochondrial",
    "geneiousPrimeID": "scenedesmusObliquusMitochondrial",
    "translations": "FFLLSS*SYY*LCC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "------*---*---*--------------------M----------------------------"
  },
  "transl_table=23": {
    "name": "Thraustochytrium Mitochondrial",
    "geneiousPrimeID": "thraustochytriumMitochondrial",
    "translations": "FF*LSSSSYY**CC*WLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--*-------**--*-----------------M--M---------------M------------"
  },
  "transl_table=24": {
    "name": "Pterobranchia Mitochondrial",
    "geneiousPrimeID": "pterobranchiaMitochondrial",
    "translations": "FFLLSSSSYY**CCWWLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSSKVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**-------M---------------M---------------M------------"
  },
  "transl_table=25": {
    "name": "Candidate Division SR1 and Gracilibacteria",
    "geneiousPrimeID": "candidateDivisionSR1andGracilibacteria",
    "translations": "FFLLSSSSYY**CCGWLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "---M------**-----------------------M---------------M------------"
  },
  "transl_table=26": {
    "name": "Pachysolen Tannophilus Nuclear",
    "geneiousPrimeID": "pachysolenTannophilusNuclear",
    "translations": "FFLLSSSSYY**CC*WLLLAPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**--*----M---------------M----------------------------"
  },
  "transl_table=27": {
    "name": "Karyorelict Nuclear",
    "geneiousPrimeID": "karyorelictNuclear",
    "translations": "FFLLSSSSYYQQCCWWLLLAPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--------------*--------------------M----------------------------"
  },
  "transl_table=28": {
    "name": "Condylostoma Nuclear",
    "geneiousPrimeID": "condylostomaNuclear",
    "translations": "FFLLSSSSYYQQCCWWLLLAPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**--*--------------------M----------------------------"
  },
  "transl_table=29": {
    "name": "Mesodinium Mitochondrial",
    "geneiousPrimeID": "mesodiniumMitochondrial",
    "translations": "FFLLSSSSYYYYCC*WLLLAPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--------------*--------------------M----------------------------"
  },
  "transl_table=30": {
    "name": "Peritrich Nuclear",
    "geneiousPrimeID": "peritrichNuclear",
    "translations": "FFLLSSSSYYEECC*WLLLAPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "--------------*--------------------M----------------------------"
  },
  "transl_table=31": {
    "name": "Blastocrithidia Nuclear",
    "geneiousPrimeID": "blastocrithidiaNuclear",
    "translations": "FFLLSSSSYYEECCWWLLLLPPPPHHQQRRRRIIIMTTTTNNKKSSRRVVVVAAAADDEEGGGG",
    "startAndStop": "----------**-----------------------M----------------------------"
  }
};
export const SequenceColors = {
  "AminoAcid": {
    "geneiousForeground": {
      "fullName": "Geneious foreground",
      "normalName": "Geneious",
      "isForeground": true,
      "colors": {
        "a": "#000000",
        "c": "#AFA101",
        "d": "#B20000",
        "e": "#FF00FF",
        "f": "#404040",
        "g": "#6B8E23",
        "h": "#8B4513",
        "i": "#2E8B57",
        "k": "#0000FF",
        "l": "#B03060",
        "m": "#C71585",
        "n": "#00B2B2",
        "p": "#FFC800",
        "q": "#4682B4",
        "r": "#579EA0",
        "s": "#FA8072",
        "t": "#F4A460",
        "v": "#00B200",
        "w": "#B27A7A",
        "y": "#006400",
        "u": "#B8860B"
      },
      "id": "geneiousForeground",
      "defaultColor": "black"
    },
    "geneiousBackground": {
      "fullName": "Geneious background",
      "normalName": "Geneious",
      "defaultColor": "#646464",
      "colors": {
        "a": "#C0C0C0",
        "c": "#FBE702",
        "d": "#FF7878",
        "e": "#FF44FF",
        "f": "#F0E68C",
        "g": "#98CA32",
        "h": "#FF8C26",
        "i": "#41C67C",
        "k": "#87CEFA",
        "l": "#FB4489",
        "m": "#FF2AFF",
        "n": "#00FFFF",
        "p": "#FFFF00",
        "q": "#64B9FF",
        "r": "#87E1E4",
        "s": "#FFB6A2",
        "t": "#FFEA89",
        "v": "#00FF00",
        "w": "#FFAFAF",
        "y": "#ADFF2F",
        "u": "#FFBF0F",
        "*": "#000000"
      },
      "id": "geneiousBackground"
    },
    "rasmolBackground": {
      "fullName": "Rasmol background",
      "normalName": "Rasmol",
      "colors": {
        "a": "#C8C8C8",
        "c": "#E6E600",
        "d": "#E60A0A",
        "e": "#E60A0A",
        "f": "#3232AA",
        "g": "#EBEBEB",
        "h": "#8282D2",
        "i": "#0F820F",
        "k": "#145AFF",
        "l": "#0F820F",
        "m": "#E6E600",
        "n": "#00DCDC",
        "p": "#DC9682",
        "q": "#00DCDC",
        "r": "#145AFF",
        "s": "#FA9600",
        "t": "#FA9600",
        "v": "#0F820F",
        "w": "#B45AB4",
        "y": "#3232AA"
      },
      "id": "rasmolBackground",
      "defaultColor": "black"
    },
    "rasmolForeground": {
      "fullName": "Rasmol foreground",
      "normalName": "Rasmol",
      "colors": {
        "a": "#C8C8C8",
        "c": "#E6E600",
        "d": "#E60A0A",
        "e": "#E60A0A",
        "f": "#3232AA",
        "g": "#EBEBEB",
        "h": "#8282D2",
        "i": "#0F820F",
        "k": "#145AFF",
        "l": "#0F820F",
        "m": "#E6E600",
        "n": "#00DCDC",
        "p": "#DC9682",
        "q": "#00DCDC",
        "r": "#145AFF",
        "s": "#FA9600",
        "t": "#FA9600",
        "v": "#0F820F",
        "w": "#B45AB4",
        "y": "#3232AA",
        "*": "#000000"
      },
      "id": "rasmolForeground",
      "defaultColor": "#646464",
      "extends": "rasmolBackground",
      "isForeground": true
    },
    "hydrophobicity": {
      "fullName": "Hydrophobicity",
      "defaultColor": "#646464",
      "colors": {
        "a": "#9D0062",
        "c": "#AD0052",
        "d": "#0700F8",
        "e": "#0A00F5",
        "f": "#FF0000",
        "g": "#7F0080",
        "h": "#2A00D5",
        "i": "#F0000F",
        "k": "#4800B7",
        "l": "#F0000F",
        "m": "#BC0043",
        "n": "#3C00C3",
        "p": "#B5004A",
        "q": "#4000BF",
        "r": "#0000FF",
        "s": "#5B00A4",
        "t": "#72008D",
        "v": "#D2002D",
        "w": "#DF0020",
        "y": "#E0001F",
        "u": "#646464",
        "*": "#000000"
      },
      "id": "hydrophobicity",
      "normalName": "Hydrophobicity"
    },
    "polarity": {
      "fullName": "Polarity",
      "defaultColor": "#646464",
      "colors": {
        "*": "#000000",
        "h": "#0000B2",
        "k": "#0000B2",
        "r": "#0000B2",
        "c": "#00B200",
        "n": "#00B200",
        "q": "#00B200",
        "s": "#00B200",
        "t": "#00B200",
        "y": "#00B200",
        "u": "#00B200",
        "a": "#AFA101",
        "f": "#AFA101",
        "g": "#AFA101",
        "i": "#AFA101",
        "l": "#AFA101",
        "m": "#AFA101",
        "p": "#AFA101",
        "v": "#AFA101",
        "w": "#AFA101",
        "d": "#B20000",
        "e": "#B20000"
      },
      "id": "polarity",
      "normalName": "Polarity"
    },
    "clustal": {
      "fullName": "Clustal",
      "defaultColor": "#646464",
      "colors": {
        "*": "#000000",
        "i": "#00B200",
        "l": "#00B200",
        "m": "#00B200",
        "v": "#00B200",
        "f": "#0082FF",
        "w": "#0082FF",
        "y": "#0082FF",
        "d": "#BD58C6",
        "e": "#BD58C6",
        "h": "#FF0000",
        "k": "#FF0000",
        "r": "#FF0000",
        "g": "#FFA500",
        "p": "#FFA500",
        "s": "#FFA500",
        "t": "#FFA500",
        "a": "#FFFFFF",
        "c": "#FFFFFF",
        "n": "#FFFFFF",
        "q": "#FFFFFF",
        "u": "#FFFFFF"
      },
      "id": "clustal",
      "normalName": "Clustal"
    },
    "structural": {
      "fullName": "Structural AAs",
      "defaultColor": "#FFFFFF",
      "colors": {
        "f": "#66FF66",
        "g": "#66FF66",
        "p": "#66FF66",
        "w": "#66FF66",
        "y": "#66FF66"
      },
      "id": "structural",
      "normalName": "Structural AAs"
    },
    "cysteines": {
      "fullName": "Cysteines",
      "defaultColor": "#FFFFFF",
      "colors": {
        "c": "#FFE200"
      },
      "id": "cysteines",
      "normalName": "Cysteines"
    },
    "byAnnotation": {
      "fullName": "By annotation",
      "isDynamic": true,
      "isForeground": true,
      "id": "byAnnotation",
      "normalName": "By annotation",
      "defaultColor": "black",
      "colors": {}
    }
  },
  "Nucleotide": {
    "geneiousForeground": {
      "fullName": "Geneious foreground",
      "normalName": "Geneious",
      "isForeground": true,
      "colors": {
        "a": "#B20000",
        "c": "#0000B2",
        "g": "#AFA101",
        "t": "#00B200"
      },
      "id": "geneiousForeground",
      "defaultColor": "black"
    },
    "geneiousBackground": {
      "fullName": "Geneious background",
      "normalName": "Geneious",
      "defaultColor": "#646464",
      "colors": {
        "a": "#FF7878",
        "c": "#7878FF",
        "g": "#FBE702",
        "t": "#00FF00"
      },
      "id": "geneiousBackground"
    },
    "electropherogramBackground": {
      "fullName": "Electropherogram background",
      "normalName": "Electropherogram",
      "defaultColor": "#787878",
      "colors": {
        "a": "#00B200",
        "c": "#0000FF",
        "g": "#000000",
        "t": "#B20000"
      },
      "id": "electropherogramBackground"
    },
    "electropherogramForeground": {
      "fullName": "Electropherogram foreground",
      "normalName": "Electropherogram",
      "defaultColor": "#787878",
      "colors": {
        "a": "#00B200",
        "c": "#0000FF",
        "g": "#000000",
        "t": "#B20000"
      },
      "id": "electropherogramForeground",
      "extends": "electropherogramBackground",
      "isForeground": true
    },
    "quality": {
      "fullName": "Quality",
      "normalName": "Geneious",
      "defaultColor": "#EBACAC",
      "colors": {
        "a": "#FF7878",
        "c": "#7878FF",
        "g": "#FBE702",
        "t": "#00FF00",
        "n": "#7C0000"
      },
      "id": "quality",
      "isDynamic": true,
      "extends": "geneiousBackground"
    },
    "byAnnotation": {
      "fullName": "By annotation",
      "isDynamic": true,
      "isForeground": true,
      "id": "byAnnotation",
      "normalName": "By annotation",
      "defaultColor": "black",
      "colors": {}
    }
  }
};